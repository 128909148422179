import React, { useState } from "react";
import Modal from "react-modal";

import "./Nav.css";

import logo from "../images/summercamp-logo.svg";

export default function Nav() {
  const [isMenuVisible, setMenuVisible] = useState(false);

  function toggleMenu() {
    setMenuVisible(!isMenuVisible);
  }

  return (
    <>
      <nav className="nav">
        <div className="container">
          <div className="nav-content">
            <div className="brand">
              <a href="/">
                <img src={logo} alt="" />
              </a>
            </div>
            <div className="menu">
              <a className="menu-link has-font-size-small" href="#projects">
                Projects
              </a>
              <a className="menu-link has-font-size-small" href="#how-it-works">
                How it works
              </a>
              <a className="menu-link has-font-size-small" href="#testimonials">
                Testimonials
              </a>
              <a className="menu-link has-font-size-small" href="#faqs">
                FAQ
              </a>
              <a
                href="https://jobs.subvisual.com/summer-camp-program"
                target="_blank"
                className="button is-small"
              >
                Join Us
              </a>
            </div>
            <button
              className="toggle"
              aria-label="Toggle Menu"
              onClick={toggleMenu}
            ></button>
          </div>
        </div>
      </nav>
      <Modal
        isOpen={isMenuVisible}
        onRequestClose={toggleMenu}
        contentLabel="Menu Modal"
        className="mobile-menu has-background-blue has-text-white"
        overlayClassName="mobile-menu-overlay"
      >
        <nav className="nav">
          <div className="brand">
            <svg
              width="212"
              height="25"
              viewBox="0 0 212 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M46.4344 13.1002C46.4344 14.8998 45.753 16.3554 44.3901 17.4669C43.0271 18.5785 41.2306 19.1342 39.0004 19.1342C37.071 19.1342 35.2568 18.8872 33.5576 18.3932V12.0681H33.6107L37.1153 18.1021C37.2746 18.3844 37.4693 18.5432 37.6994 18.5785C37.9649 18.6138 38.3366 18.6314 38.8145 18.6314C39.9119 18.6314 40.7881 18.3667 41.443 17.8374C42.0979 17.3081 42.4253 16.5583 42.4253 15.5879C42.4253 14.7587 42.151 14.0971 41.6023 13.603C41.0536 13.0914 40.1066 12.518 38.7614 11.8828L37.9383 11.4858C37.2303 11.1506 36.6285 10.833 36.1329 10.5331C35.6373 10.2155 35.124 9.82736 34.593 9.36863C34.0797 8.89226 33.6815 8.33649 33.3983 7.70133C33.1328 7.04853 33 6.32515 33 5.5312C33 3.85508 33.6284 2.51418 34.8851 1.50851C36.1418 0.502836 37.8144 0 39.9031 0C41.62 0 43.3192 0.202899 45.0007 0.608696V6.64273H44.9476L41.3633 0.926277C41.2217 0.714557 41.0447 0.591053 40.8323 0.555767C40.6376 0.520481 40.381 0.502837 40.0624 0.502837C39.1066 0.502837 38.3366 0.758666 37.7525 1.27032C37.1861 1.78198 36.9029 2.49654 36.9029 3.41399C36.9029 4.1903 37.1595 4.81664 37.6728 5.29301C38.2038 5.76938 39.0712 6.29868 40.2748 6.88091L41.2571 7.35728C41.9474 7.69251 42.4961 7.9748 42.9032 8.20417C43.3103 8.41589 43.7617 8.707 44.2573 9.07751C44.7706 9.43038 45.16 9.78325 45.4255 10.1361C45.7087 10.489 45.9477 10.9212 46.1424 11.4329C46.3371 11.9446 46.4344 12.5003 46.4344 13.1002Z"
                fill="white"
              />
              <path
                d="M60.9176 5.76938V16.8582C60.9176 17.1582 60.9972 17.3787 61.1565 17.5199L62.4841 18.8167V18.8696H56.5633V16.0378C56.227 16.9729 55.7049 17.7139 54.9969 18.2609C54.2889 18.8078 53.457 19.0813 52.5012 19.0813C51.1913 19.0813 50.1913 18.6138 49.501 17.6787C48.8107 16.7436 48.4655 15.3762 48.4655 13.5766C48.4655 12.5709 48.4832 10.639 48.5186 7.78073C48.5186 7.51608 48.439 7.30435 48.2797 7.14556L46.9787 5.82231V5.76938H52.8463L52.8198 9.26277C52.8198 11.5917 52.8198 12.8267 52.8198 12.9679C52.8198 14.2558 52.9525 15.235 53.218 15.9055C53.4835 16.5759 53.9614 16.9112 54.6517 16.9112C55.3774 16.9112 56.0146 16.5142 56.5633 15.7202V7.80719C56.5633 7.54254 56.4837 7.322 56.3244 7.14556L55.0234 5.82231V5.76938H60.9176Z"
                fill="white"
              />
              <path
                d="M85.7614 11.1418C85.7614 11.8299 85.7437 12.8973 85.7083 14.3441C85.6906 15.7908 85.6817 16.6642 85.6817 16.9641C85.6817 17.2287 85.7702 17.4405 85.9472 17.5993L87.2482 18.8167V18.8696H79.8141V18.8167L81.062 17.5993C81.239 17.4228 81.3275 17.2199 81.3275 16.9906C81.3275 16.92 81.3363 16.1525 81.354 14.6881C81.3894 13.2237 81.4071 12.2445 81.4071 11.7505C81.4071 10.4272 81.2567 9.43038 80.9558 8.75993C80.6726 8.08948 80.1416 7.75426 79.3628 7.75426C78.5485 7.75426 77.8494 8.13359 77.2653 8.89226C77.4246 9.56271 77.5042 10.3125 77.5042 11.1418C77.5042 11.7769 77.4865 12.8356 77.4511 14.3176C77.4334 15.782 77.4246 16.6642 77.4246 16.9641C77.4246 17.2287 77.5042 17.4405 77.6635 17.5993L78.991 18.8167V18.8696H71.5304V18.8167L72.8048 17.5993C72.9641 17.4405 73.0438 17.2376 73.0438 16.9906C73.0438 16.9376 73.0526 16.179 73.0703 14.7146C73.1057 13.2325 73.1234 12.2445 73.1234 11.7505C73.1234 10.4272 72.9818 9.43038 72.6986 8.75993C72.4154 8.08948 71.8844 7.75426 71.1056 7.75426C70.3268 7.75426 69.6719 8.14241 69.1409 8.91872V16.9641C69.1409 17.2464 69.2206 17.4581 69.3799 17.5993L70.6808 18.8167V18.8696H63.2467V18.8167L64.5477 17.5993C64.707 17.4581 64.7867 17.2464 64.7867 16.9641V7.80719C64.7867 7.54254 64.707 7.322 64.5477 7.14556L63.2467 5.82231V5.76938H69.1409V8.57468C69.8666 6.56333 71.2738 5.55766 73.3624 5.55766C75.3625 5.55766 76.6458 6.59862 77.2122 8.68054C77.5308 7.76308 78.0795 7.01324 78.8583 6.43101C79.6371 5.84878 80.5664 5.55766 81.6461 5.55766C82.9736 5.55766 83.9913 6.03403 84.6994 6.98677C85.4074 7.93952 85.7614 9.32452 85.7614 11.1418Z"
                fill="white"
              />
              <path
                d="M110.341 11.1418C110.341 11.8299 110.323 12.8973 110.288 14.3441C110.27 15.7908 110.261 16.6642 110.261 16.9641C110.261 17.2287 110.35 17.4405 110.527 17.5993L111.828 18.8167V18.8696H104.394V18.8167L105.642 17.5993C105.819 17.4228 105.907 17.2199 105.907 16.9906C105.907 16.92 105.916 16.1525 105.934 14.6881C105.969 13.2237 105.987 12.2445 105.987 11.7505C105.987 10.4272 105.836 9.43038 105.535 8.75993C105.252 8.08948 104.721 7.75426 103.942 7.75426C103.128 7.75426 102.429 8.13359 101.845 8.89226C102.004 9.56271 102.084 10.3125 102.084 11.1418C102.084 11.7769 102.066 12.8356 102.031 14.3176C102.013 15.782 102.004 16.6642 102.004 16.9641C102.004 17.2287 102.084 17.4405 102.243 17.5993L103.571 18.8167V18.8696H96.1101V18.8167L97.3846 17.5993C97.5439 17.4405 97.6235 17.2376 97.6235 16.9906C97.6235 16.9376 97.6324 16.179 97.6501 14.7146C97.6855 13.2325 97.7032 12.2445 97.7032 11.7505C97.7032 10.4272 97.5616 9.43038 97.2784 8.75993C96.9952 8.08948 96.4642 7.75426 95.6853 7.75426C94.9065 7.75426 94.2516 8.14241 93.7206 8.91872V16.9641C93.7206 17.2464 93.8003 17.4581 93.9596 17.5993L95.2605 18.8167V18.8696H87.8265V18.8167L89.1274 17.5993C89.2867 17.4581 89.3664 17.2464 89.3664 16.9641V7.80719C89.3664 7.54254 89.2867 7.322 89.1274 7.14556L87.8265 5.82231V5.76938H93.7206V8.57468C94.4463 6.56333 95.8535 5.55766 97.9421 5.55766C99.9422 5.55766 101.225 6.59862 101.792 8.68054C102.111 7.76308 102.659 7.01324 103.438 6.43101C104.217 5.84878 105.146 5.55766 106.226 5.55766C107.553 5.55766 108.571 6.03403 109.279 6.98677C109.987 7.93952 110.341 9.32452 110.341 11.1418Z"
                fill="white"
              />
              <path
                d="M124.885 11.724H116.92V11.7769C116.92 13.4001 117.274 14.6175 117.982 15.4291C118.69 16.2231 119.601 16.6201 120.716 16.6201C122.593 16.6201 123.938 15.8085 124.752 14.1853H124.805C124.133 17.4493 122.177 19.0813 118.938 19.0813C117.114 19.0813 115.575 18.5167 114.318 17.3875C113.061 16.2584 112.433 14.5999 112.433 12.4121C112.433 10.1008 113.061 8.3806 114.318 7.25142C115.575 6.12225 117.168 5.55766 119.097 5.55766C119.84 5.55766 120.539 5.6547 121.194 5.84878C121.849 6.04285 122.46 6.34279 123.026 6.74859C123.61 7.15439 124.071 7.73662 124.407 8.49528C124.743 9.2363 124.911 10.1185 124.911 11.1418C124.911 11.3182 124.903 11.5123 124.885 11.724ZM119.044 6.0605C118.353 6.0605 117.831 6.4663 117.477 7.27789C117.123 8.07184 116.937 9.39509 116.92 11.2476H120.823C120.84 11.0183 120.849 10.7536 120.849 10.4537C120.849 8.81286 120.69 7.67487 120.371 7.0397C120.07 6.3869 119.628 6.0605 119.044 6.0605Z"
                fill="white"
              />
              <path
                d="M136.91 6.43101L135.449 10.586H135.396L133.299 8.09831C133.139 7.90423 132.98 7.86012 132.821 7.96598C132.485 8.21299 132.104 8.72465 131.679 9.50096V16.6465C131.679 16.9112 131.812 17.1317 132.077 17.3081L134.679 18.8167V18.8696H125.785V18.8167L127.086 17.5993C127.245 17.4581 127.325 17.2464 127.325 16.9641V7.80719C127.325 7.54254 127.245 7.322 127.086 7.14556L125.785 5.82231V5.76938H131.679V9.15691C131.927 8.11595 132.343 7.26025 132.927 6.5898C133.511 5.90171 134.237 5.55766 135.104 5.55766C135.865 5.55766 136.467 5.84878 136.91 6.43101Z"
                fill="white"
              />
              <path
                d="M157.406 0.502837V7.01324H157.353L153.318 0.926277C153.141 0.643984 152.849 0.502837 152.441 0.502837H152.07C149.379 0.502837 148.034 3.5375 148.034 9.60681C148.034 15.6232 149.397 18.6314 152.123 18.6314H152.415C152.822 18.6314 153.114 18.5079 153.291 18.2609L157.353 12.0151H157.406V18.6579C155.672 18.9754 153.787 19.1342 151.751 19.1342C148.671 19.1342 146.37 18.2962 144.848 16.6201C143.326 14.9263 142.565 12.5885 142.565 9.60681C142.565 6.64273 143.352 4.30498 144.928 2.59358C146.503 0.864525 148.742 0 151.645 0C153.627 0 155.548 0.167612 157.406 0.502837Z"
                fill="white"
              />
              <path
                d="M170.756 10.9036C170.756 11.0095 170.747 11.1947 170.73 11.4594C170.73 11.7064 170.73 11.8387 170.73 11.8563L170.57 15.6144C170.517 16.6377 170.756 17.1494 171.287 17.1494C171.623 17.1494 171.871 16.9906 172.031 16.673H172.084C172.084 17.0259 172.013 17.3434 171.871 17.6257C171.393 18.5961 170.526 19.0813 169.269 19.0813C168.473 19.0813 167.8 18.8608 167.252 18.4197C166.703 17.9609 166.411 17.3258 166.375 16.5142C166.11 17.2905 165.65 17.9168 164.995 18.3932C164.358 18.8519 163.614 19.0813 162.765 19.0813C161.738 19.0813 160.879 18.7549 160.189 18.1021C159.499 17.4316 159.154 16.5142 159.154 15.3497C159.154 14.1147 159.596 13.1443 160.481 12.4386C161.384 11.7152 162.428 11.3535 163.614 11.3535C164.818 11.3535 165.756 11.5917 166.428 12.0681L166.482 9.2363V9.10398C166.482 7.07499 165.836 6.0605 164.543 6.0605C164.278 6.0605 164.11 6.07814 164.039 6.11343C163.986 6.14871 163.915 6.23693 163.827 6.37808L161.676 10.3478H161.623L159.95 6.56333C161.826 5.89289 163.614 5.55766 165.313 5.55766C167.19 5.55766 168.561 5.96346 169.429 6.77505C170.314 7.60429 170.756 8.98047 170.756 10.9036ZM165.446 12.1739C164.774 12.1739 164.243 12.4298 163.853 12.9414C163.481 13.4531 163.296 14.1059 163.296 14.8998C163.296 15.6056 163.437 16.1613 163.72 16.5671C164.021 16.9553 164.402 17.1494 164.862 17.1494C165.464 17.1494 165.968 16.8406 166.375 16.2231V16.1966L166.428 12.3327C166.11 12.2269 165.782 12.1739 165.446 12.1739Z"
                fill="white"
              />
              <path
                d="M195.307 11.1418C195.307 11.8299 195.289 12.8973 195.254 14.3441C195.236 15.7908 195.227 16.6642 195.227 16.9641C195.227 17.2287 195.316 17.4405 195.493 17.5993L196.794 18.8167V18.8696H189.36V18.8167L190.608 17.5993C190.785 17.4228 190.873 17.2199 190.873 16.9906C190.873 16.92 190.882 16.1525 190.9 14.6881C190.935 13.2237 190.953 12.2445 190.953 11.7505C190.953 10.4272 190.802 9.43038 190.501 8.75993C190.218 8.08948 189.687 7.75426 188.908 7.75426C188.094 7.75426 187.395 8.13359 186.811 8.89226C186.97 9.56271 187.05 10.3125 187.05 11.1418C187.05 11.7769 187.032 12.8356 186.997 14.3176C186.979 15.782 186.97 16.6642 186.97 16.9641C186.97 17.2287 187.05 17.4405 187.209 17.5993L188.537 18.8167V18.8696H181.076V18.8167L182.351 17.5993C182.51 17.4405 182.589 17.2376 182.589 16.9906C182.589 16.9376 182.598 16.179 182.616 14.7146C182.651 13.2325 182.669 12.2445 182.669 11.7505C182.669 10.4272 182.528 9.43038 182.244 8.75993C181.961 8.08948 181.43 7.75426 180.651 7.75426C179.873 7.75426 179.218 8.14241 178.687 8.91872V16.9641C178.687 17.2464 178.766 17.4581 178.926 17.5993L180.227 18.8167V18.8696H172.792V18.8167L174.093 17.5993C174.253 17.4581 174.332 17.2464 174.332 16.9641V7.80719C174.332 7.54254 174.253 7.322 174.093 7.14556L172.792 5.82231V5.76938H178.687V8.57468C179.412 6.56333 180.819 5.55766 182.908 5.55766C184.908 5.55766 186.191 6.59862 186.758 8.68054C187.076 7.76308 187.625 7.01324 188.404 6.43101C189.183 5.84878 190.112 5.55766 191.192 5.55766C192.519 5.55766 193.537 6.03403 194.245 6.98677C194.953 7.93952 195.307 9.32452 195.307 11.1418Z"
                fill="white"
              />
              <path
                d="M198.334 22.9981V7.80719C198.334 7.54254 198.255 7.322 198.095 7.14556L196.794 5.82231V5.76938H202.662V8.57468C203.529 6.56333 204.901 5.55766 206.777 5.55766C208.264 5.55766 209.468 6.14871 210.388 7.33082C211.326 8.49528 211.795 10.0655 211.795 12.0416C211.795 14.1412 211.264 15.8437 210.202 17.1494C209.14 18.4373 207.795 19.0813 206.167 19.0813C204.343 19.0813 203.175 18.4109 202.662 17.07V22.8393C202.662 23.0158 202.68 23.1393 202.715 23.2099C202.768 23.2804 202.883 23.3775 203.06 23.501L205.025 24.8507V24.9036H196.794V24.8507L198.095 23.6333C198.255 23.4745 198.334 23.2628 198.334 22.9981ZM204.653 7.67487C203.874 7.67487 203.211 8.08948 202.662 8.91872V16.8847C203.069 17.3258 203.644 17.5463 204.388 17.5463C205.149 17.5463 205.804 17.1141 206.352 16.2495C206.919 15.3674 207.202 14.0353 207.202 12.2533C207.202 9.20102 206.352 7.67487 204.653 7.67487Z"
                fill="white"
              />
              <path
                d="M13.2379 12.6783C13.2379 9.65603 10.9095 8.42662 8.13636 7.12036L7.16837 6.65933C5.12774 5.68605 3.8458 4.94327 3.8458 3.30405C3.8458 1.51116 5.12774 0.486645 6.95908 0.486645C7.19453 0.486645 7.42999 0.486645 7.71777 0.53787C7.97939 0.589095 8.05788 0.640321 8.24101 0.896449L11.7729 6.42882H11.8252V0.589095C9.99386 0.153678 8.16252 0 6.8021 0C2.66852 0 0 2.10025 0 5.35308C0 8.55468 2.53771 10.0402 4.86612 11.116L5.67714 11.5002C8.05788 12.6015 9.28749 13.3699 9.28749 15.086C9.28749 16.9813 7.84858 18.0314 5.72946 18.0314C5.38936 18.0314 5.02309 18.0314 4.63066 17.9802C4.31672 17.929 4.23823 17.8265 4.0551 17.5192L0.601725 11.6794H0.549401V17.8009C2.17144 18.2619 4.34288 18.5181 5.9126 18.5181C10.334 18.5181 13.2379 16.1617 13.2379 12.6783Z"
                fill="white"
              />
              <path
                d="M17.6761 4.17489C18.8534 4.17489 19.7952 3.43212 19.7952 2.27954C19.7952 1.05013 18.8534 0.281742 17.6761 0.281742C16.5511 0.281742 15.5831 1.05013 15.5831 2.27954C15.5831 3.43212 16.5511 4.17489 17.6761 4.17489Z"
                fill="white"
              />
            </svg>
            <button
              className="close"
              aria-label="Close modal"
              onClick={toggleMenu}
            ></button>
          </div>
          <div className="menu-item has-font-size-large">
            <a
              className="has-text-white"
              onClick={toggleMenu}
              href="#testimonials"
            >
              Testimonials
            </a>
          </div>
          <div className="menu-item has-font-size-large">
            <a className="has-text-white" onClick={toggleMenu} href="#projects">
              Projects
            </a>
          </div>
          <div className="menu-item has-font-size-large">
            <a className="has-text-white" onClick={toggleMenu} href="#faqs">
              FAQs
            </a>
          </div>
          <div>
            <a
              href="https://jobs.subvisual.com/summer-camp-program"
              className="button has-font-size-small has-text-white"
            >
              Join us
            </a>
          </div>
        </nav>
      </Modal>
    </>
  );
}
